import React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Box,
  Container,
  Heading
} from '@chakra-ui/react'
import SliceZone from '../components/SliceZone/SliceZone'
import HomepageSlider from '../components/PageHero/HomepageSlider'
import HomepageNav from '../components/HomepageNav/HomepageNav'


// markup
const IndexPage = ( props ) => {
  
  const { location, data } = props;
  const pageLocation = location.pathname;

  const document = data.prismicHomepage.data
  return (
    <Layout pageLocation={pageLocation}>
      <Seo title="Willowcreek Design Group"  />
      {/* <PageHero 
        heroImage={document.hero_image.localFile}
        alt={document.hero_image.alt}
        heroText={document.hero_text.text}
        btnLink={document.hero_button_link.url}
        btnLabel={document.hero_button_label.text}
      /> */}
      <HomepageSlider data={data} />
      <Container maxW="container.xl">
        <Box my="12">
        <Heading as="h1" textAlign="center" fontWeight="400">
          {document.hero_text.text}
        </Heading>
        </Box>
        
        <SliceZone sliceZone={document.body}/>

        <HomepageNav />
      </Container>

    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    _previewable
    data {
      page_title {
        text
      }
      hero_text {
        text
      }
      hero_button_link {
        url
        uid
      }
      hero_button_label {
        text
      }
      homepage_slider {
        hero_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.75
                layout: FULL_WIDTH
                transformOptions: {cropFocus: CENTER})
            }
          }
          alt
        }
      }
      body {
        ... on PrismicHomepageDataBodyPassionStatement {
          id
          slice_type
          primary {
            subtitle {
              text
              richText
            }
            statement_title {
              text
            }
          }
        }
        ... on PrismicHomepageDataBodyFeaturedService {
          id
          slice_type
          primary {
            service_title {
              text
            }
            service_text {
              text
            }
            color
            service_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            button_link {
              uid
              url
            }
            button_label {
              text
            }
          }
        }
        ... on PrismicHomepageDataBodyBeforeAfter {
          slice_type
          primary {
            before_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            after_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          id
        }
      }
    }
  }
}
`

export default withPrismicPreview(IndexPage);
