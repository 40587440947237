import React from 'react'
import {
    Box,
    useColorMode,
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'    

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      gap: 0,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


const HomepageSlider = ({ data }) => {
   const slides = data.prismicHomepage.data.homepage_slider

    //console.log(slides)

    const { colorMode } = useColorMode()
    return (
        <>
        <Box position="relative" maxH="80vh">
            <Box>
            <Carousel 
            swipeable={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            centerMode={false}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]} 
        >
            {slides.map((slide, index) => {
                return (
                    <Box maxH="80vh">
                      <GatsbyImage image={getImage(slide.hero_image.localFile)} alt={slide.hero_image.alt} key={index} objectFit="contain" objectPosition="top center"/>
                    </Box>
                )
            })}

            </Carousel>
            </Box>
        </Box>
        </>
    )
}

export default HomepageSlider;