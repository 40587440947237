import React from 'react'
import {
    Box,
    Flex,
    Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { motion } from 'framer-motion'

const MotionBox = motion(Box);

const HomepageNav = () => {
    return (
        <Flex justifyContent="space-between" w="100%" alignItems="center" mb="16" flexWrap="wrap">
                <MotionBox
                width={{ base: '50%', md: 'auto'}}
                textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 0.25
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/portfolio" variant="home-nav-link" activeClassName="active" partiallyActive>Portfolio</Link>
            </MotionBox>
            <MotionBox
            width={{ base: '50%', md: 'auto'}}
            textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 0.5
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/services" variant="home-nav-link" activeClassName="active">Services</Link>
            </MotionBox>
            <MotionBox
            width={{ base: '50%', md: 'auto'}}
            textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 0.75
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/about" variant="home-nav-link" activeClassName="active">Meet the Team</Link>
            </MotionBox>
            {/* <MotionBox
            width={{ base: '50%', md: 'auto'}}
            textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 1
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/instagram" variant="home-nav-link" activeClassName="active">Instagram</Link>
            </MotionBox> */}
            <MotionBox
            width={{ base: '50%', md: 'auto'}}
            textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 1.25
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/press" variant="home-nav-link" activeClassName="active">Press</Link>
            </MotionBox>
            <MotionBox
            width={{ base: '50%', md: 'auto'}}
            textAlign="center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ 
                    duration: 1,
                    delay: 1.5
                }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 }
                }}
                >
                <Link as={GatsbyLink} to="/contact" variant="home-nav-link" activeClassName="active">Contact</Link>
            </MotionBox>
        </Flex>
    )
}

export default HomepageNav;